<template>
<div>
  <a-carousel autoplay >
      <div class="hellobg" >
        <div class="bg-margin">
          <div class="frist-left">
            <h1>人证比对设备</h1>
            <div class="explain">人证核验+测温 云存储管理系统解决方案</div>
            <div class="content">人证比对、精准测量、蓝牙连接、即时传输、高效通过、实时追溯</div>
            <div class="button">
              <a-button type="primary" icon="download" size="large" shape="round">
                了解更多
              </a-button>
            </div>
          </div>
          <div class="frist-right">
            <img src="https://www.znffj.com/images/homepage/content1.png" alt="人证比对">
          </div>
        </div>
      </div>
      <div class="fullscreen">
        <img src="../assets/hall/1.png" alt="">
      </div>
      <div class="fullscreen">
        <img src="../assets/disinfect/1.png" alt="">
      </div>
       <div class="fullscreen">
        <img src="../assets/medicalcare/1.png" alt="">
      </div>
       <div class="fullscreen">
        <img src="../assets/serve/1.png" alt="">
      </div>
    </a-carousel>
<div class="bg2">
    <a-row >
      <a-col :span="24" justify="center" align="middle" class="dititle">
       米茉智能提供专业的产品服务
      </a-col>
    </a-row>
    <a-row  style="margin-top:60px">
      <a-col :xs="24" :sm="24" :md="8" >
       <a-card hoverable :bordered="false">
         <a-card-meta title="服务安全稳定" description="提供企业级稳定、精确的服务。支持毫秒级的识别响应能力及99.99%的可靠性保障">
          <a-avatar
            slot="avatar"
            src="https://www.znffj.com/images/homepage/icon/component.png"
            size="large"
          />
        </a-card-meta>
       </a-card>
      </a-col>
      <a-col :xs="24" :sm="24" :md="8" >
       <a-card hoverable :bordered="false">
         <a-card-meta title="完整配套服务" description=" 支持Windows，鸿蒙系统,开发板等不同平台下的B/S,C/S架构应用。">
          <a-avatar
            slot="avatar"
            src="http://www.znffj.com/images/homepage/icon/heart.png"
            size="large"
          />
        </a-card-meta>
       </a-card>
      </a-col>
      <a-col :xs="24" :sm="24" :md="8" >
       <a-card hoverable :bordered="false">
         <a-card-meta title="云监控" description="分布式云环境集中监控，统一资源及应用状态视图，智能分析及故障定位。">
          <a-avatar
            slot="avatar"
            src="http://www.znffj.com/images/homepage/icon/smile.png"
            size="large"
          />
        </a-card-meta>
       </a-card>
      </a-col>
      <a-col :xs="24" :sm="24" :md="8"  >
       <a-card hoverable :bordered="false">
         <a-card-meta title="有效检测" description="支持身份证、NFC、IC、RFID等多种卡片有效检测">
          <a-avatar
            slot="avatar"
            src="http://www.znffj.com/images/homepage/icon/template.png"
            size="large"
          />
        </a-card-meta>
       </a-card>
      </a-col>
      <a-col :xs="24" :sm="24" :md="8"  >
       <a-card hoverable :bordered="false">
         <a-card-meta title="本地化部署" description="采用离线本地化部署，可在无网环境或内网环境下使用，保证数据安全。">
          <a-avatar
            slot="avatar"
            src="http://www.znffj.com/images/homepage/icon/engine.png"
            size="large"
          />
        </a-card-meta>
       </a-card>
      </a-col>
      <a-col :xs="24" :sm="24" :md="8" >
       <a-card hoverable :bordered="false">
         <a-card-meta title="定制化开发" description="支持多种协议通讯，支持定制化开发。">
          <a-avatar
            slot="avatar"
            src="http://www.znffj.com/images/homepage/icon/set.png"
            size="large"
          />
        </a-card-meta>
       </a-card>
      </a-col>



    </a-row>
  </div>
  <div class="bg2">
    <a-row >
      <a-col :span="24" justify="center" align="middle" class="dititle">
       公司资质
      </a-col>
    </a-row>
    <a-row  style="margin-top:10px">
      <a-col :xs="24" :sm="24" :md="6" v-for="index in 20" :key="index">
       <img class="mycert" :src='"../assets/cert/cert"+index+".png"' alt="">
      </a-col>

    </a-row>
  </div>
</div>

</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url('../assets/css/helloworld.css');

.ant-card{
 background:transparent;
}
.mycert{
  margin: 4px;
  object-fit: contain;
  max-height: 40vh;
}
</style>
